import styled from "styled-components";

const DetailsHeader = styled.div``;

const Details = () => {
    return (
        <>
        </>
    );
};

export default Details;