import { useEffect, useState, useContext } from 'react';
import { tgc } from '../App';
import { APIRouter } from '../Data/Middleware';
import { settings } from '../Helper/BaseData';
import { eventHandler } from '../Helper/Helper';
import Section from '../UIComponents/Section/Section';
import Switch from '../UIComponents/Switch/Switch';
import styles from './Settings.style';

const Settings = () => {
    // states
    const [switches, setSwitches] = useState(settings);

    // context
    const { loader, setGrowl } = useContext(tgc);

    // upload handler
    const onUpload = () => {
        console.log("uploading...")
        eventHandler('click', document.querySelector("[class*='file-upload']"))
    };

    useEffect(() => {
        const file = document.querySelector("[class*='file-upload']");
        file.addEventListener('change', (e) => {
            // Get the selected file
            const file = e.target.files;
            console.log(file)
            // Get the file name and size
            const { name: fileName, size } = file;
            // Convert size in bytes to kilo bytes
            const fileSize = (size / 1000).toFixed(2);
            // Set the text content
            const fileNameAndSize = `${fileName} - ${fileSize}KB`;
            eventHandler('click', document.querySelector('[class*=file-upload-submit]'));
            e.preventDefault();
        });
    }, []);

    const formSubmit = (e) => {
        console.log("submit")
        document.getElementById("profile-picture-form").submit()
        e.preventDefault();
        return false;
    }

    const saveSettings = (e) => {
        setGrowl({
            title: 'Settings',
            message: 'Your settings were successfully saved!',
            type: 'success',
        });
    };

    const uploadSucess = () => {
        console.log("uploaded")
    }

    return (
        <Section loader={loader}>
            <styles.SettingsWrapper>
                <styles.SettingsTitle>
                    Settings
                </styles.SettingsTitle>
                <styles.SettingsSectionsWrapper>
                <styles.SettingsSwitchesWrapper>
                {
                    switches.map((s, i) => {
                        return (
                            <Switch key={`${s}_${i}`} switchText={s.setting} />
                            )
                        })
                }
                </styles.SettingsSwitchesWrapper>
                <styles.SettingsSectionsDivider />
                <styles.SettingsProfileWrapper>
                    <styles.ProfilePictureUploadWrapper>
                        <styles.ProfilePicture />
                        <styles.ProfilePictureForm 
                            id="profile-picture-form" 
                            method={'post'} 
                            onSubmit={formSubmit} 
                            action={'https://api.truckgrub.io/Uploads/uploader.php'} 
                            encType="multipart/form-data"
                            target={'dummyframe'}
                        >
                            <styles.UploadProfilePicture className={'upload_profile_pic_button'} fieldName={'signUp_submitBtn'} color={'#607d8b'} name={'Upload Picture'} onClick={onUpload} type={'button'}>
                                Upload Picture
                            </styles.UploadProfilePicture>
                            {/*TODO: figure out why you can't use styled components for the data inputs*/}
                            <input type="hidden" id="Key" name="user" value={JSON.parse(localStorage.getItem('user')).id} />
                            <input style={{ visibility: "hidden", display: "block" }} name="fileToUpload" id="fileToUpload" className={'file-upload'} type={'file'} />
                            <styles.ProfilePictureSubmitInput className={'file-upload-submit'} type={'submit'} name="pp-submit" />
                        </styles.ProfilePictureForm>
                    </styles.ProfilePictureUploadWrapper>
                </styles.SettingsProfileWrapper>
                </styles.SettingsSectionsWrapper>
                <styles.ProfileDescriptionWrapper>
                    <styles.ProfileDescriptionTitle>Profile Description</styles.ProfileDescriptionTitle>
                    <styles.ProfileDescription></styles.ProfileDescription>
                </styles.ProfileDescriptionWrapper>
            </styles.SettingsWrapper>
            <styles.SettingsFooterWrapper>
                <styles.SettingsFooterButtons>
                    <styles.SettingsFooterSaveButton className={'settings_save'} fieldName={'settings_saveBtn'} color={'#607d8b'} name={'Save Changes'} onClick={saveSettings} type={'button'}>
                    </styles.SettingsFooterSaveButton>
                </styles.SettingsFooterButtons>
            </styles.SettingsFooterWrapper>
            <iframe name="dummyframe" id="dummyframe" style={{ display: 'none' }}></iframe>
        </Section>
    );
};

export default Settings;