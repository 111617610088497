import styled from 'styled-components';

const TextAreaComponent = styled.textarea`
    padding: 10px;
    font-family: 'Heebo', sans-serif;
    border: 1px solid #999;
    border-radius: 3px;
    &:focus {
        border: 1px solid #444;
        outline: 0 none;
    };
`;

const TextArea = ({ value, className, fieldName, placeholder, onChange }) => {
    return (
        <TextAreaComponent
            value={value}
            className={className}
            fieldName={fieldName}
            placeholder={placeholder}
            onChange={onChange}
        ></TextAreaComponent>
    );
};

export default TextArea;