import axios from 'axios';
import qs from 'qs';

const toggleLoader = (setLoader, loader, isOn, target) => {
    setLoader({
        ...loader,
        loading: isOn,
        target
    });
};

export class API {
    async signUp(setLoader, loader, data, target) {
        try {
            toggleLoader(setLoader, loader, true, target);
            return axios.post('https://api.truckgrub.io/SignUpLogin/signUp.php', qs.stringify(data, {
                parseArrays: false
            })).then(res => {
                toggleLoader(setLoader, loader, false);
                return res;
            })
        } catch(err) {
            toggleLoader(setLoader, loader, false);
            console.log(err);
        }
    };

    async resendVerification(setLoader, loader, data, target) {
        try {
            toggleLoader(setLoader, loader, true, target);
            return axios.post('https://api.truckgrub.io/SignUpLogin/Email/email.php?resendVerification', qs.stringify(data, {
                parseArrays: false
            })).then(res => {
                toggleLoader(setLoader, loader, false);
                return res;
            })
        } catch (err) {
            toggleLoader(setLoader, loader, false);
            console.log(err);
        }
    };

    async login(setLoader, loader, data, target) {
        try {
            toggleLoader(setLoader, loader, true, target);
            return axios.post('https://api.truckgrub.io/SignUpLogin/login.php', qs.stringify(data, {
                parseArrays: false
            })).then(res => {
                toggleLoader(setLoader, loader, false);
                return res;
            })
        } catch(err) {
            toggleLoader(setLoader, loader, false);
            console.log(err);
        }
    };

    async validation(setLoader, loader, data, target) {
        try {
            toggleLoader(setLoader, loader, true, target);
            return axios.post('https://api.truckgrub.io/SignUpLogin/validate.php', qs.stringify(data, {
                parseArrays: false
            })).then(res => {
                toggleLoader(setLoader, loader, false);
                return res;
            })
        } catch(err) {
            toggleLoader(setLoader, loader, false);
            console.log(err);
        }
    };

    async faq(setLoader, loader) {
        try {
            toggleLoader(setLoader, loader, true);
            return axios.get('https://api.truckgrub.io/Help/faq.php').then(res => {
                toggleLoader(setLoader, loader, false);
                return res;
            })
        } catch(err) {
            toggleLoader(setLoader, loader, false);
            console.log(err);
        }
    };

    async submitProfilePicture(setLoader, loader, data, target) {
        try {
            toggleLoader(setLoader, loader, true, target);
            return axios.post('https://api.truckgrub.io/Uploads/uploader.php', qs.stringify(data, {
                parseArrays: false
            })).then(res => {
                toggleLoader(setLoader, loader, false);
                return res;
            })
        } catch(err) {
            toggleLoader(setLoader, loader, false);
            console.log(err);
        }
    };
}

export const APIRouter = async (setLoader, loader, endPoint, data, target) => {
    const api = new API();
    return api[endPoint](setLoader, loader, data, target).then(res => {
        return res;
    });
}