import { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { tgc } from '../../App';
import { FaTimes } from 'react-icons/fa';

const GrowlWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 250px;
    z-index: 99999;
    opacity: 1.0;
    color: #fff;
    padding: 5px 15px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    height: auto;
    background-color: ${({ type }) => 
        type === 'Error'
            ? '#f44336'
            : type === 'Warning'
                ? '#ffc107'
                : '#4caf50'
    };
`;
const GrowlTitle = styled.p`
    font-size: 15px;
    font-weight: bold;
`;
const GrowlMessage = styled.p`
    font-size: 13.5px;
`;
const GrowlCloseButton = styled(FaTimes)`
    font-size: 20px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
`;

const Growl = ({ title, message, type, timeout=500000, allowClose=false }) => {
    const { setGrowl } = useContext(tgc);

    useEffect(() => {
        if(!allowClose) {
            setTimeout(() => {
                document.getElementById("growl-wrapper").classList.add('growl-fade-out');
                setTimeout(() => {
                    setGrowl(() => null);
                }, 2000)
            }, timeout);
        }
    }, []);

    return (
        <GrowlWrapper id={'growl-wrapper'} type={type}>
            { allowClose && <GrowlCloseButton onClick={() => setGrowl(() => null)} /> }
            <GrowlTitle>{title}</GrowlTitle>
            <GrowlMessage>{message}</GrowlMessage>
        </GrowlWrapper>
    );
};

export default Growl;