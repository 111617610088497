import styled from "styled-components";
import { getStyles } from "../Helper/Helper";
import Button from "../UIComponents/Button/Button";
import Input from "../UIComponents/Input/Input";
import TextArea from "../UIComponents/TextArea/TextArea";

const desktopStyles = {
    SettingsWrapper: styled.div`
        margin: 100px 10% 150px 10%;
        text-align: left;
        letter-spacing: 3px;
    `,
    SettingsTitle: styled.h1`
        font-size: 35px;
        font-family: 'Raleway', sans-serif;
        margin-bottom: 100px;
    `,
    ProfilePictureUploadWrapper: styled.div`
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        width: 35%;
        flex-wrap: wrap;
    `,
    ProfilePicture: styled.img`
        border-radius: 100px;
        width: 150px;
        background-color: #e8e8e8;
        height: 150px;
        border: 0;
        margin-bottom: 35px;
    `,
    SettingsSectionsWrapper: styled.div`
        display: flex;
        flex-direction: flex-start;
        justify-content: space-around;
    `,
    SettingsSwitchesWrapper: styled.div``,
    SettingsSectionsDivider: styled.div`
        width: 1px;
        background-color: #e8e8e8;
        height: auto;
    `,
    SettingsProfileWrapper: styled.div``,
    ProfilePictureForm: styled.form``,
    UploadProfilePicture: styled(Button)`
        color: #fff;
        border: 0;
        border-radius: 5px;
        padding: 12px;
        cursor: pointer;
        text-align: center;
        margin-left: 16px;
        margin-top: -30px;
    `,
    ProfilePictureUserInput: styled(Input)``,
    ProfilePictureFileInput: styled(Input)`
        visibility: hidden;
        display: block;
    `,
    ProfilePictureSubmitInput: styled(Input)`
        visibility: hidden;
        display: block;
    `,
    ProfileDescriptionWrapper: styled.div`
        display: flex;
        flex-direction: column;
    `,
    ProfileDescription: styled(TextArea)`
        height: 100px;
        width: -webkit-fill-available;
        resize: none;
        display: block;
        margin-top: 25px;
    `,
    ProfileDescriptionTitle: styled.div`
        display: block;
    `,
    SettingsFooterWrapper: styled.div`
        position: fixed;
        width: -webkit-fill-available;
        background-color: #fff;
        bottom: 0;
        padding: 15px 25px;
        border-top: 1px solid #e8e8e8;
    `,
    SettingsFooterButtons: styled.div`
        text-align: right;
    `,
    SettingsFooterSaveButton: styled(Button)``,
};

const mobileStyles = {
    SettingsWrapper: styled.div`
        margin: 35% 30px;
        text-align: center;
        letter-spacing: 3px;
    `,
    SettingsTitle: styled.h1`
        font-size: 35px;
        font-family: 'Raleway', sans-serif;
        margin-bottom: 40px;
    `,
    ProfilePictureUploadWrapper: styled.div``,
    ProfilePicture: styled.img`
        border-radius: 100px;
        width: 100px;
        height: 100px;
    `,
    ProfilePictureUploadInput: styled(Input)``,
}

export default getStyles(desktopStyles, mobileStyles);