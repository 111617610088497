import { useEffect, useState, useContext } from "react";
import { isNullEmptyUndefined } from "../Helper/Helper";
import styled from "styled-components";
import Map from "../UIComponents/Map/Map";
import Details from "./Details";
import { useNavigate } from "react-router-dom";
import { tgc } from "../App";

const ProfileWrapper = styled.div`
    display: flex;
`;
const DetailsWrapper = styled.div`
    width: 25%;
    background-color: #fff;
`;
const MapWrapper = styled.div`
    height: 100vh;
    width: 100%;
`;

const Home = () => {
    // states
    const [lat, setLat] = useState();
    const [long, setLong] = useState();
    const [zoom, setZoom] = useState();
    const navigate = useNavigate();

    // context
    const { setGrowl } = useContext(tgc);

    // find location services
    if(navigator.geolocation) {
        console.log("went in")
        navigator.geolocation.getCurrentPosition((pos) => {
            console.log("yes")
            setLat(pos.coords.latitude);
            setLong(pos.coords.longitude);
            setZoom(11);
            sessionStorage.setItem('location_denied', 'false');
        }, (error) => {
            if (error.PERMISSION_DENIED) {
                setLat(39.399933);
                setLong(-99.5815614);
                setZoom(5);
                if(sessionStorage.getItem('location_denied') !== 'true') {
                    setGrowl({
                        type: 'Warning',
                        title: 'Cannot Find Current Location',
                        message: 'For a better user experience, please turn on location services',
                        allowClose: true,
                    });
                    sessionStorage.setItem('location_denied', 'true');
                }
            } else if (error.POSITION_UNAVAILABLE) {

            } else {

            }
        })
    } else {
        console.log("nope")
    }

    useEffect(() => {
        console.log(localStorage.getItem('user'))
        if(isNullEmptyUndefined(localStorage.getItem('user'))) {
            navigate('/');
        }
    }, [])

    return (
        <ProfileWrapper>
            <DetailsWrapper>
                <Details />
            </DetailsWrapper>
            <MapWrapper>
                { lat && long && zoom && <Map zoom={zoom} lat={lat} long={long} /> }
            </MapWrapper>
        </ProfileWrapper>
    );
};

export default Home;