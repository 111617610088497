import { GoogleMap, useLoadScript } from '@react-google-maps/api';

const Map = ({ zoom, lat, long, place }) => {
    console.log(zoom, lat, long)
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    });

    return (
        isLoaded && <GoogleMap zoom={zoom} center={{lat: lat, lng: long}} mapContainerClassName="map-container"></GoogleMap>
    );
};

export default Map;